import classes from "./styles.module.scss";
import clsx from "classnames";
import { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

export const Heading = ({ className, children, variant }) => {
  const [visible, setVisible] = useState(false);
  const contentRef = useRef();
  const ruleRef = useRef();

  useEffect(() => {
    if (contentRef.current && "getBoundingClientRect" in contentRef.current) {
      const rect = contentRef.current.getBoundingClientRect();
      const panelWidth = rect.width + rect.x;
      ruleRef.current.style.width = `${visible ? panelWidth : 0}px`;
    }
  }, [visible]);

  return (
    <VisibilitySensor partialVisibility={true} onChange={setVisible}>
      <div className={clsx(className, classes.heading, variant)}>
        <div ref={contentRef} className="d-inline-block position-relative">
          {children}
          <span ref={ruleRef} className={classes.heading__rule} />
        </div>
      </div>
    </VisibilitySensor>
  );
};

Heading.defaultProps = {
  variant: "primary",
  className: "mb-0 mb-md-4",
  containerClassName: "px-0",
};
